import request from '@/utils/request'


// 查询船舶信息列表
export function listShipping(query) {
  return request({
    url: '/gather/gather-shipping/list',
    method: 'get',
    params: query
  })
}

// 查询船舶信息分页
export function pageShipping(query) {
  return request({
    url: '/gather/gather-shipping/page',
    method: 'get',
    params: query
  })
}

// 查询船舶信息详细
export function getShipping(data) {
  return request({
    url: '/gather/gather-shipping/detail',
    method: 'get',
    params: data
  })
}

// 新增船舶信息
export function addShipping(data) {
  return request({
    url: '/gather/gather-shipping/add',
    method: 'post',
    data: data
  })
}

// 修改船舶信息
export function updateShipping(data) {
  return request({
    url: '/gather/gather-shipping/edit',
    method: 'post',
    data: data
  })
}

// 删除船舶信息
export function delShipping(data) {
  return request({
    url: '/gather/gather-shipping/delete',
    method: 'post',
    data: data
  })
}
